import CreateSshModal from "@app/components/ssh-modal";
import Button from "@app/components/button";
import ConfirmModal from "@app/components/confirm-modal";
import { addToast } from "@app/components/toast/toast.service";
import { SystemMessage } from "@app/constants";
import SshService from "@app/services/http/ssh.service";
import { openPortalDialog } from "@app/services/modal.service";
import { Ssh } from "@app/types";
import useForceUpdate from "@core/hooks/use-force-update.hook";
import useObservable from "@core/hooks/use-observable.hook";
import { useEffect, useState } from "react";

function SshManagement() {
  const [sshs, setSshs] = useState<Ssh[]>([]);

  const [update, forceUpdate] = useForceUpdate();
  const { subscribeOnce, subscribeUntilDestroy } = useObservable();

  useEffect(() => {
    subscribeUntilDestroy(SshService.getSshs(), (sshs) => {
      if (sshs.length) {
        setSshs(sshs);
      } else {
        setSshs([]);
      }
    });
  }, [update]);

  const handleCreateSsh = () => {
    const createSshModalObs = openPortalDialog(CreateSshModal);

    createSshModalObs.afterClosed().subscribe((data) => {
      if (data?.ssh) {
        addToast({ text: SystemMessage.ADD_SUCCESSFULLY });
        forceUpdate();
      }
    });
  };

  const handleUpdateSsh = (ssh: Ssh) => {
    const createSshModalObs = openPortalDialog(CreateSshModal, ssh);

    createSshModalObs.afterClosed().subscribe((data) => {
      if (data?.ssh) {
        addToast({ text: SystemMessage.UPDATE_SUCCESSFULLY });
        forceUpdate();
      }
    });
  };

  const handleDeleteSsh = (sshId: string) => {
    const deleteSshObs = openPortalDialog(ConfirmModal, {
      message: "Bạn có chắc chắn xóa ssh này không?",
    });

    deleteSshObs.afterClosed().subscribe((data) => {
      if (data?.isAccept) {
        subscribeOnce(SshService.deleteSsh(sshId), () => {
          addToast({ text: SystemMessage.DELETE_SUCCESSFULLY });
          forceUpdate();
        });
      }
    });
  };

  return (
    <div className="w-full mx-auto mt-4 pb-4">
      <div className="pb-2 px-2 border-b border-[#409EFF]">
        <div className="flex justify-end">
          <Button
            label="Thêm SSH"
            width="fit-content"
            className="px-2 text-sm"
            onClick={handleCreateSsh}
          />
        </div>
      </div>
      <div className="px-2 overflow-x-auto mt-2">
        <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead className="text-white">
              <tr className="border-b-2 border-gray-200 bg-[#409EFF] text-sm">
                <th className="p-3 text-left w-60">IP ADDRESS</th>
                <th className="p-3 text-left w-72">USERNAME</th>
                <th className="p-3 text-left w-72">PASSWORD</th>
                <th className="p-3 text-left w-40">ROUTER</th>
                <th className="p-3 text-left w-40">DOMAIN</th>
                <th className="p-3 text-left w-40">HÀNH ĐỘNG</th>
              </tr>
            </thead>
            <tbody>
              {!!sshs.length &&
                sshs.map((ssh) => (
                  <tr
                    key={ssh._id}
                    className="border-b border-gray-200 text-sm hover:bg-[#51b83c2c]"
                  >
                    <td className="p-3">{ssh.ip_address}</td>
                    <td className="p-3">{ssh.username}</td>
                    <td className="p-3">{ssh.password}</td>
                    <td className="p-3">{ssh.router}</td>
                    <td className="p-3">{ssh.domain}</td>
                    <td className="p-3 flex gap-1">
                      <Button
                        label="Xóa"
                        width="fit-content"
                        className="px-1 text-sm"
                        onClick={() => handleDeleteSsh(ssh._id)}
                      />
                      <Button
                        label="Update"
                        width="fit-content"
                        className="px-1 text-sm"
                        onClick={() => handleUpdateSsh(ssh)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!sshs.length && (
            <div className="flex justify-center">Không có dữ liệu</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SshManagement;
