import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import { Form, Formik, FormikContextType } from "formik";
import { FormControl } from "../form-control";
import Input from "../input";
import Button from "../button";
import { createSshValidationSchema } from "@app/validations";
import { SshInitialValues, CreateSshRequest, Server } from "@app/types";
import { createRef, useEffect, useState } from "react";
import useObservable from "@core/hooks/use-observable.hook";
import SshService from "@app/services/http/ssh.service";
import ServerService from "@app/services/http/server.service";
import { Select } from "../select/select";
import TextArea from "../textarea";

function CreateSshModal({ portalDialogRef, portalData }: PortalDialogProps) {
  const formRef = createRef<FormikContextType<SshInitialValues>>();
  const [servers, setServers] = useState<Server[]>([]);

  useEffect(() => {
    subscribeOnce(ServerService.getServers(), (servers) => {
      setServers(servers);
    });
  }, []);

  const { subscribeOnce } = useObservable();

  const handleSubmit = (values: SshInitialValues) => {
    const createSshReq: CreateSshRequest = {
      ip_address: values.ip_address,
      username: values.username,
      password: values.password,
      router: values.router,
      domain: values.domain,
    };

    if (portalData) {
      subscribeOnce(
        SshService.updateSsh(portalData._id, createSshReq),
        (ssh) => {
          portalDialogRef.close({ ssh });
        }
      );
    } else {
      subscribeOnce(SshService.createSsh(createSshReq), (ssh) => {
        portalDialogRef.close({ ssh });
      });
    }
  };

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="w-[500px] p-5 max-sm:w-[300px]">
        <div className="font-bold text-xl">
          {portalData ? "Update SSH" : "Thêm SSH"}
        </div>
        <Formik
          initialValues={{
            ip_address: portalData ? portalData.ip_address : "",
            username: portalData ? portalData.username : "",
            password: portalData ? portalData.password : "",
            router: portalData ? portalData.router : "",
            domain: portalData ? portalData.domain : "",
          }}
          validationSchema={createSshValidationSchema}
          innerRef={formRef}
          onSubmit={handleSubmit}
          validateOnChange
          validateOnBlur
        >
          <Form>
            <div className="mt-3">
              <div className="text-sm">IP ADDRESS</div>
              <FormControl name="ip_address">
                <Input
                  className="mt-1 !max-w-none text-sm"
                  errorClassName="text-sm"
                />
              </FormControl>
            </div>
            <div className="mt-3">
              <div className="text-sm">USERNAME</div>
              <FormControl name="username">
                <Input
                  className="mt-1 !max-w-none text-sm"
                  errorClassName="text-sm"
                />
              </FormControl>
            </div>
            <div className="mt-3">
              <div className="text-sm">PASSWORD</div>
              <FormControl name="password">
                <Input
                  className="mt-1 !max-w-none text-sm"
                  errorClassName="text-sm"
                />
              </FormControl>
            </div>
            <div className="mt-3">
              <div className="text-sm">
                ROUTER (Các wan cách nhau bởi dấu phẩy)
              </div>
              <FormControl name="router">
                <TextArea
                  className="mt-1 !max-w-none text-sm"
                  width="100%"
                  errorClassName="text-sm"
                  placeholder="wan1 - vnpt - 1000 đ, wan2 - viettel - 2000 đ"
                />
              </FormControl>
            </div>
            <div className="mt-8">
              <div className="text-sm">DOMAIN</div>
              <FormControl name="domain">
                <Select
                  width="fit-content"
                  options={servers.map((server) => ({
                    label: `Server: ${server.name} - Status: ${
                      server.status ? "ON" : "OFF"
                    } - Domain: ${server.domain}`,
                    value: server.domain,
                  }))}
                />
              </FormControl>
            </div>
            <Button
              type="submit"
              label={portalData ? "Update" : "Thêm"}
              containerClassName="mt-5 mx-auto"
              width="fit-content"
              className="px-6 text-sm"
              labelClassName="font-bold"
            />
          </Form>
        </Formik>
      </div>
    </Modal>
  );
}

export default CreateSshModal;
